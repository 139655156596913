<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-6"
              :src="require(`@/assets/images/bm/bm_logo.svg`)"
            />
            <span class="text-white text-lg ml-3">
              Blue<span class="font-medium">Moon</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/bm/login_info.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign in to your account.
            </div>
            <!-- <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your e-commerce accounts in one place
            </div> -->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
          <h1
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              KODLLIN DEMO
            </h1>
            <h3
              class="intro-x text-2xl xl:text-3xl text-center xl:text-left mt-4"
            >
              Sign In
            </h3>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <form @submit.prevent="formSubmit">
            <div class="intro-x mt-8">

              <input
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
                 v-model="email"
              />
              <input
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Password"
                 v-model="password"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >
              </div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" type="submit">
                Login
              </button>
            </div>
            </form>
            <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Login Successfuly!</div>
            <div class="text-gray-600 mt-1">
                Welcome to Blue Moon management system.
            </div>
        </div>
    </div>

    <div id="failed-notification-content" class="toastify-content hidden flex">
        <InfoIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Invalid Login!</div>
            <div class="text-gray-600 mt-1">
                Please Check Username and Password and try again.
            </div>
        </div>
    </div>

          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constant/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'

export default defineComponent({
  // components: {
  //   DarkModeSwitcher
  // },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  },
  data() {
    return {
      status: 'default',
      email: '',
      password: '',
      loginVal: '',
      req: axios.create({
        baseUrl: BASE_URL
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'processing', 'loginError'])
  },

  methods: {
    ...mapActions(['login']),
    formSubmit() {
      this.status = 'processing'
      this.login({
        email: this.email,
        password: this.password
      })
    }
  },
  watch: {
    currentUser(val) {
      if (val != null) {
        this.status = 'success'
        Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
        setTimeout(() => {
          this.$router.push({ name: 'dashboard-view' })
        }, 100)
      }
    },
    loginError(val) {
      if (val != null) {
        this.status = 'fail'
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        setTimeout(() => {
          this.status = 'default'
        }, 2000)
      }
    }
  }
})
</script>
